
import '../App.css';
import Footer from '../components/Footer';
import Nav from '../components/Nav';
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

function Apply() {
    const currentTab = "apply";
    const form = useRef();
    const [ptype, setPtype] = useState('none');
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_mibuylb', "template_n5203oe", form.current, 'x8KNZiDZHPSwkwm4_')
            .then((result) => {
                console.log(result.text);
                window.location.href = "/";

            }, (error) => {
                console.log(error.text);
            });
        document.getElementById('first_name').value = '';
        document.getElementById('last_name').value = '';

        document.getElementById('name_of_business').value = '';
        document.getElementById('user_email').value = '';

        document.getElementById('business_phone').value = '';
        document.getElementById('user_phone').value = '';

        document.getElementById('industry').value = '';
        document.getElementById('credit_score').value = '';

        document.getElementById('time_in_business').value = '';
        document.getElementById('gross_annual').value = '';


    };
    return (
        <>
            <Nav Tab={currentTab}></Nav>
            <div className="apply">
                <h1>Apply</h1>
                <form ref={form} onSubmit={sendEmail}>
                    <h2>Submit your details below</h2>
                    <div className="formsection">
                        <div className='formflex'>
                            <div className="topblock"><div className="label"><label>1. First Name</label></div>
                                <input placeholder=' ' type="text" name="first_name" id="first_name" required /></div>
                            <div className="topblock"><div className="label"><label>2. Last Name</label></div>
                                <input placeholder=' ' type="text" name="last_name" id="last_name" required /></div>
                        </div>
                        <div className='formflex'>
                            <div className="topblock"><div className="label"><label>3. Name of Business</label></div>
                                <input placeholder=' ' type="text" name="name_of_business" id="name_of_business" required /></div>
                            <div className="topblock"><div className="label"><label>4. Email Address</label></div>
                                <input placeholder=' ' type="email" name="user_email" id="user_email" required /></div>
                        </div>
                        <div className='formflex'>
                            <div className="topblock"><div className="label"><label>5. Business Phone</label></div>
                                <input placeholder=' ' type="tel" name="business_phone" id="business_phone" required /></div>
                            <div className="topblock"><div className="label"><label>6. Mobile Phone</label></div>
                                <input placeholder=' ' type="tel" name="user_phone" id="user_phone" required /></div>
                        </div>
                        <div className='formflex'>
                            <div className="topblock"><div className="label"><label>7. Industry</label></div>
                                <input placeholder=' ' type="text" name="industry" id="industry" required /></div>
                            <div className="topblock"><div className="label"><label>8. Approximate Credit Score</label></div>
                                <input placeholder=' ' type="text" name="credit_score" id="credit_score" required /></div>
                        </div>
                        <div className='formflex'>
                            <div className="topblock"><div className="label"><label>9. Time in Business</label></div>
                                <input placeholder=' ' type="text" name="time_in_business" id="time_in_business" required /></div>
                            <div className="topblock"><div className="label"><label>10. Gross Annual Sales</label></div>
                                <select name="gross_annual" id="gross_annual" required>


                                    <>
                                        <option value=""></option>
                                        <option value="detached">Under $50,000</option>
                                        <option value="detached">$50,000 - $120,000</option>
                                        <option value="detached">$120,000 - $360,000</option>
                                        <option value="detached">$360,000 - $1,000,000</option>
                                        <option value="detached">$1,000,000 - $1,800,000</option>
                                        <option value="detached">$1,800,000 - $3,000,000</option>
                                        <option value="detached">$3,000,000 +</option>
                                    </>

                                </select></div>
                        </div>
                    </div>


                    <div className="centerbut">
                        <input placeholder=' ' className='sendbutton' type="submit" value="Send" />
                    </div>

                </form>
            </div>
            <Footer></Footer>
        </>
    );
}

export default Apply;
