
import '../App.css';
import Nav from "../components/Nav"
import Footer from "../components/Footer"
import { Link } from 'react-router-dom'

function Home() {
  const currentTab = "home";
  return (
    <>
      <div className='home'>
        <Nav Tab={currentTab}></Nav>
        <div className="top-section">
          <div className="overlay-left">
            <h1>C<span className='span1'>O</span> <span className='span2'>FUND</span> <span className='span3'>ME</span></h1>
            <h2>Helping you access the funding solutions to elevate your business today.</h2>
          </div>
        </div>
        <div className="how-it-works">
          <div className="how-it-works-tophalf"></div>
          <h1>How it works</h1>
          <div className="container">
            <Link to="/apply" className="item greyback">
              <div className="number">1</div>
              <div className="image"><img src="form.svg" alt="" /></div>

              <h3>1. Click here to complete our 30 second contact form (intake form)</h3>
            </Link>

            <div className="item greyback">
              <div className="number">2</div>
              <div className="image"><img src="advisors.svg" alt="" /></div>
              <h3>2. One of our advisors will be assigned to you for a quick and free consultation</h3>
            </div>

            <div className="item greyback">
              <div className="number">3</div>
              <div className="image"><img src="review.svg" alt="" /></div>
              <h3>3. Review your options</h3>
            </div>
      
            <div className="item greyback">
              <div className="number">4</div>
              <div className="image"><img src="choose.svg" alt="" /></div>
              <h3>4. Choose your offer</h3>
            </div>

            <div className="item greyback">
              <div className="number">5</div>
              <div className="image"><img src="getfunded.svg" alt="" /></div>
              <h3>5. Get Funded! <br />(This usually happens between 24-72 hours)</h3>
            </div>
          </div>
        </div>
        <div className="why-us">
          <div className="inner">
            <div className="left">
              <h1>Why choose us?</h1>
              <p>
                1.	Quick application process <br />
                2.	Excellent support <br />
                3.	Quick funding <br />

              </p>
            </div>
            <div className="line"></div>
            <div className="right">
              <h1>Any questions?</h1>
              <a href="tel:8559943546">
                <img src="call.svg" alt="" /> Call us today!</a>
            </div>
          </div>
        </div>
        <Footer></Footer>

      </div>
    </>
  );
}

export default Home;
