
import '../App.css';
import { Link } from 'react-router-dom'

function Footer() {

    return (
        <>
            <footer>
                <div className="inner-footer">
                    <div className="left">
                        <img src="logo.png" alt="" />
                        <p>
                        We are passionate about helping businesses achieve the highest level of success by providing them with the best options to obtain commercial and business lending options. We have strived to make this a simple process with unparalleled support and through more than 5 years of industry knowledge have succeeded in assisting hundreds of merchants get back on their feet and back to business!
                        </p>
                    </div>
                    <div className="right">
                        <h2>Contact</h2>
                        <a href="mailto:submissions@cofundme.net">submissions@cofundme.net</a> <br />
                        <a href="tel:8559943546">8559943546</a>
                    </div>

                </div>
            </footer>
        </>
    );
}

export default Footer;
