
import '../App.css';
import Nav from '../components/Nav';
import Footer from '../components/Footer';
function Products() {
    const currentTab = "products";


    return (
        <>
            <Nav Tab={currentTab}></Nav>

            <div className="products">
                <h1>Products</h1>
                <div className="product">
                    <div className="left">
                        <h1>Merchant Cash Advance</h1>
                        <p>
                            Merchant Cash advances are best for small businesses that need capital immediately to cover cash-flow shortages or short-term expenses. They provide alternative financing to a traditional small-business loan and consist of purchasing a percentage of your business’ future sales and is paid back on a daily or weekly basis.
                        </p>
                    </div>
                    <div className="right">
                        <h2>Pros</h2>
                        <ul>
                            <li>
                                <img src="check.svg" alt="" />
                                Quick and easy funding (same day funding is possible)
                            </li>
                            <li>
                                <img src="check.svg" alt="" />
                                Flexible requirements (Low FICO ok)
                            </li>
                            <li>
                                <img src="check.svg" alt="" />
                                Repayment based on your sales
                            </li>
                            <li>
                                <img src="check.svg" alt="" />
                                No collateral needed
                            </li>
                            <li>
                                <img src="check.svg" alt="" />
                                Minimal documentation required
                            </li>
                        </ul>
                        <h2>Requirements</h2>
                        <ul>
                            <li>
                                <img src="exclamation.svg" alt="" />
                                No minimum FICO
                            </li>
                            <li>
                                <img src="exclamation.svg" alt="" />
                                Minimum 15k in monthly sales
                            </li>
                            <li>
                                <img src="exclamation.svg" alt="" />
                                Minimum 1 year in business
                            </li>

                        </ul>
                    </div>
                </div>
                <div className="product">
                    <div className="left">
                        <h1>Business Line of Credit</h1>
                        <p>
                            A business line of credit is a flexible loan for businesses that works like a credit card. You can draw money from the credit lines as needed, only paying interest on the portion of money borrowed. As you repay the amount borrowed, funds available are replenished for future use.
                        </p>
                    </div>
                    <div className="right">
                        <h2>Pros</h2>
                        <ul>
                            <li>
                                <img src="check.svg" alt="" />
                                Available when you need it
                            </li>
                            <li>
                                <img src="check.svg" alt="" />
                                Quick application process
                            </li>
                            <li>
                                <img src="check.svg" alt="" />
                                No collateral needed
                            </li>
                            <li>
                                <img src="check.svg" alt="" />
                                Weekly/monthly repayment options
                            </li>

                        </ul>
                        <h2>Requirements</h2>
                        <ul>
                            <li>
                                <img src="exclamation.svg" alt="" />
                                600 Minimum FICO
                            </li>
                            <li>
                                <img src="exclamation.svg" alt="" />
                                Minimum 1 Year in business
                            </li>
                            <li>
                                <img src="exclamation.svg" alt="" />
                                Minimum 15k in Monthly Sales
                            </li>

                        </ul>
                    </div>
                </div>
                <div className="product">
                    <div className="left">
                        <h1>Term Loan</h1>
                        <p>
                        A traditional type of bank loan, a lump sum is provided and repaid with fixed interest over a set period of time. These types of loans are usually repaid on a monthly basis with a term of 1-6 years.                        </p>
                    </div>
                    <div className="right">
                        <h2>Pros</h2>
                        <ul>
                            <li>
                                <img src="check.svg" alt="" />
                                Longer repayment terms
                            </li>
                            <li>
                                <img src="check.svg" alt="" />
                                Monthly payments possible
                            </li>
                            <li>
                                <img src="check.svg" alt="" />
                                No collateral needed
                            </li>
                            <li>
                                <img src="check.svg" alt="" />
                                Quick application process
                            </li>

                        </ul>
                        <h2>Requirements</h2>
                        <ul>
                            <li>
                                <img src="exclamation.svg" alt="" />
                                650 Minimum FICO
                            </li>
                            <li>
                                <img src="exclamation.svg" alt="" />
                                Minimum 1 Year in business
                            </li>
                            <li>
                                <img src="exclamation.svg" alt="" />
                                Minimum 20k in monthly sales
                            </li>

                        </ul>
                    </div>
                </div>
                <div className="product">
                    <div className="left">
                        <h1>Equipment Financing</h1>
                        <p>
                        A type of funding utilized to purchase business-related equipment and does not require a merchant to use up a large portion of their cash which frees up working capital.</p>
                    </div>
                    <div className="right">
                        <h2>Pros</h2>
                        <ul>
                            <li>
                                <img src="check.svg" alt="" />
                                Weekly/ Monthly payments with a fixed interest rate
                            </li>
                            <li>
                                <img src="check.svg" alt="" />
                                At the end of the repayment terms, you will own the equipment outright
                            </li>
                            <li>
                                <img src="check.svg" alt="" />
                                Quick and easy application
                            </li>
                        </ul>
                        <h2>Requirements</h2>
                        <ul>
                            <li>
                                <img src="exclamation.svg" alt="" />
                                600 Minimum FICO
                            </li>
                            <li>
                                <img src="exclamation.svg" alt="" />
                                Minimum 1 Year in business
                            </li>
                            <li>
                                <img src="exclamation.svg" alt="" />
                                Minimum 15k in monthly sales
                            </li>

                        </ul>
                    </div>
                </div>
            </div>

            <Footer></Footer>
        </>
    );
}

export default Products;
