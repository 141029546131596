
import '../App.css';
import { Link } from 'react-router-dom'

function Nav({ Tab }) {
    function toggleMenu() {
        const menu = document.getElementById("mobileNavContainer");
        const menuicon = document.getElementById("menuIcon");
        menuicon.classList.toggle("close-button");
        menu.classList.toggle("open-menu");
    }
    return (
        <>
            <div className='mobile-nav' id="mobileNavContainer">
                <div className="mobile-nav-inner">
                    <Link className={Tab == "home" ? "link linkactive" : "link"} to="/" >Home</Link>
                    <div className="divider"></div>
                    {/*<Link className={Tab == "discover" ? "link linkactive" : "link"} to="/discover" >DISCOVER</Link>
                    <div className="divider"></div>*/}
                    <Link className={Tab == "products" ? "link linkactive" : "link"} to="/products" >Products</Link>
                    <div className="divider"></div>
                    <Link className={Tab == "faq" ? "link linkactive" : "link"} to="/faq" >FAQ</Link>
                    <div className="divider"></div>
                    <Link className={Tab == "apply" ? "link linkactive" : "link"} to="/apply" >Apply</Link>

                </div>


            </div>
            <div className="menu-button" id="menuIcon" onClick={() => toggleMenu()}>
                <div className="line-top"></div>
                <div className="line-bottom"></div>
            </div>
            <nav>
                <Link className='mainlogowrap' to="/"><img src="logo.png" className='mainlogo' alt="" /></Link>
                <div className='hide-800'>
                    <Link className={Tab == "home" ? "link hide-800 linkactive" : "link hide-800"} to="/" >Home</Link>
                    <Link className={Tab == "products" ? "link hide-800 linkactive dropdown" : "link hide-800 dropdown"} to="/products" >
                        <span>Products</span>
                        <div class="dropdown-content">
                        <Link className="link" to="/products" >Merchant Cash Advance</Link> <br />
                        <Link className="link" to="/products" >Term Loan</Link><br />
                        <Link className="link" to="/products" >Business line of credit</Link><br />
                        <Link className="link" to="/products" >Equipment Financing</Link>
                    </div>
                    </Link>
                  
                    <Link className={Tab == "faq" ? "link hide-800 linkactive" : "link hide-800"} to="/faq" >FAQ</Link>
                    <Link className={Tab == "apply" ? "link hide-800 linkactive" : "link hide-800"} to="/apply" >Apply</Link>
                </div>
            </nav>
        </>
    );
}

export default Nav;
