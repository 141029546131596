
import '../App.css';
import Nav from '../components/Nav';
import Footer from '../components/Footer';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
function FAQ() {
    const currentTab = "faq";

    return (
        <>
            <Nav Tab={currentTab}></Nav>
            <div className="faq">
                <h1>FAQ</h1>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>DO I QUALIFY?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                        Our minimum qualifications are a personal FICO credit score of 500, your business must be over 6 months old and you need to average a minimum of $15,000 in gross monthly revenue over the last 3 months.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>WHAT CAN I USE THE MONEY FOR?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                        There’s no restriction on what you can you use the money for, though we recommend putting the funds towards income multiplying activities. Our customers usually use the funds for: purchasing inventory, payroll financing, equipment financing, marketing campaign funding, business expansion capital.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>WHAT DETERMINES MY RATE?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                        Your actual rate is calculated based on a proprietary algorithm which takes several factors into consideration. Each application is considered based on your bank transactions, cash flow, and partially on your credit.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>HOW DOES REPAYMENT WORK?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                        There are multiple options. Depending on your preferences, business type and setup, you can choose between: daily, weekly and monthly ACH payment options.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>HOW LONG CAN I BORROW MONEY FOR?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                        Depending on your eligibility, you will be able to keep the funds for up to 60 months.                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>HOW QUICKLY CAN I GET FUNDING?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                        Depending on the product you qualify for, it can take as little as a few hours to receive the money in your account. Thanks to our quick application process and determined advisors, we will always make sure you will receive the funds you need, when you need them.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </div>

            <Footer></Footer>
        </>
    );
}

export default FAQ;
